<template>
  <div class="Socials">
    <a v-for="(social, i) in socials"
       :key="i"
        :href="social.href"
       class="Socials-Link"
       target="_blank"
    >
      <img loading="lazy" :src="require(`@/assets/images/${social.icon}`)" :alt="social.alt" class="Socials-Icon">
    </a>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Socials",
  computed: {
    ...mapGetters({
      socials: 'getSocials'
    })
  }
}
</script>

<style lang="scss">
.Socials {
  display: flex;
  align-items: center;

  &-Link {
    margin-right: 26px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>